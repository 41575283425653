/* eslint-disable */
import React, { FC } from 'react';
import { CriteriaBlockPage, CriteriasType, TemplateType } from 'store/search/search.types';
import { sortCriteria } from 'store/search/utils';
import { Logic } from '../Selects/MultiValueSelect/components/MultiSelectWithConditions/MultiSelectWithConditions';
import CriteriaSimpleList from './components/CriteriaSimpleList';
import CriteriaMultiList from './components/CriteriaMultiList';
import { sortByBlocks } from './utils';
import { OptionType } from '../Selects/MultiValueSelect/multiValuesSelect';

export const IconQuestion = () => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM8 13.8125C4.79063 13.8125 2.1875 11.2094 2.1875 8C2.1875 4.79063 4.79063 2.1875 8 2.1875C11.2094 2.1875 13.8125 4.79063 13.8125 8C13.8125 11.2094 11.2094 13.8125 8 13.8125Z"
			fill="#A3AEBE"
		/>
		<path
			d="M9.74375 4.94844C9.275 4.5375 8.65625 4.3125 8 4.3125C7.34375 4.3125 6.725 4.53906 6.25625 4.94844C5.76875 5.375 5.5 5.94844 5.5 6.5625V6.68125C5.5 6.75 5.55625 6.80625 5.625 6.80625H6.375C6.44375 6.80625 6.5 6.75 6.5 6.68125V6.5625C6.5 5.87344 7.17344 5.3125 8 5.3125C8.82656 5.3125 9.5 5.87344 9.5 6.5625C9.5 7.04844 9.15625 7.49375 8.62344 7.69844C8.29219 7.825 8.01094 8.04687 7.80937 8.3375C7.60469 8.63437 7.49844 8.99062 7.49844 9.35156V9.6875C7.49844 9.75625 7.55469 9.8125 7.62344 9.8125H8.37344C8.44219 9.8125 8.49844 9.75625 8.49844 9.6875V9.33281C8.49925 9.18113 8.54574 9.03321 8.63187 8.90834C8.71799 8.78347 8.83975 8.68746 8.98125 8.63281C9.90312 8.27813 10.4984 7.46563 10.4984 6.5625C10.5 5.94844 10.2312 5.375 9.74375 4.94844ZM7.375 11.4375C7.375 11.6033 7.44085 11.7622 7.55806 11.8794C7.67527 11.9967 7.83424 12.0625 8 12.0625C8.16576 12.0625 8.32473 11.9967 8.44194 11.8794C8.55915 11.7622 8.625 11.6033 8.625 11.4375C8.625 11.2717 8.55915 11.1128 8.44194 10.9956C8.32473 10.8783 8.16576 10.8125 8 10.8125C7.83424 10.8125 7.67527 10.8783 7.55806 10.9956C7.44085 11.1128 7.375 11.2717 7.375 11.4375Z"
			fill="#A3AEBE"
		/>
	</svg>
);

export type Page = 'calls' | 'reports' | 'reports_block' | 'markup' | 'checklists' | 'callsFromReportCell'; // страницы, где используется CriteriaItem / CriteriaWithConditions
export type SortedCriteriasListType = {
	blockNumber: number;
	criterias: CriteriasType[];
	complexCriterias: Record<string, CriteriasType[]>;
	// complexCriterias: CriteriasType[];
};
export type HandlerOnChange = (event: any, criteria: CriteriasType, logic?: Logic) => void;
export type OnAddCondition = (data: CriteriasType, page: string, index: number) => void;
export type CustomValueHandler = (
	values: OptionType[],
	itemLogic?: Logic | undefined,
	itemId?: number | undefined,
) => void;

interface FindCriteriasPropsType {
	allCriterias: CriteriasType[] | null;
	activeCriterias: CriteriasType[];
	block?: string;
	handlerOnChange?: HandlerOnChange;
	isHandlerFunction?: boolean;
	page: Page;
	// простой список в строчку (например, в отчетах "точный фильтр")
	simpleList?: boolean;
	// список в колонку с названиями (например, в чек-листах)
	simpleListWithTitles?: boolean;
	// индекс добавленного столбца в параметрах отчета
	reportBlockIndex?: number;
	// функция, вызывающаяся при добавлении/удалении логического условия
	onAddCondition?: OnAddCondition;
	onDeleteCondition?: OnAddCondition;

	// доп свойства для заголовков
	blockTitle?: string;
	onTemplateSelectValueChange?: (event: any) => void;
	convertedTemplate?: {
		value: any;
		label: string;
	}[];
	currentTemplate?: TemplateType | null;
	allTemplates?: TemplateType[];
	onClearTemplateClick?: () => void;
}

const CriteriasList: FC<FindCriteriasPropsType> = React.memo(
	({
		allCriterias,
		activeCriterias,
		block,
		handlerOnChange,
		isHandlerFunction,
		simpleList,
		simpleListWithTitles,
		page,
		onAddCondition,
		onDeleteCondition,
		blockTitle,
		onTemplateSelectValueChange,
		convertedTemplate,
		currentTemplate,
		allTemplates,
		onClearTemplateClick,
	}) => {
		const sortedCriteriasArray = sortByBlocks(activeCriterias);
		const { complexCriteria, otherCriteria } = sortCriteria(activeCriterias);

		return simpleList ? (
			<CriteriaSimpleList
				allCriterias={allCriterias as CriteriasType[]}
				otherCriteria={otherCriteria}
				complexCriteria={complexCriteria}
				block={block as CriteriaBlockPage}
				isHandlerFunction={isHandlerFunction}
				handlerOnChange={handlerOnChange}
				page={page}
				onAddCondition={onAddCondition}
				onDeleteCondition={onDeleteCondition}
			/>
		) : (
			<CriteriaMultiList
				simpleListWithTitles={simpleListWithTitles}
				activeCriterias={activeCriterias}
				allCriterias={allCriterias as CriteriasType[]}
				sortedCriterias={sortedCriteriasArray}
				block={block as CriteriaBlockPage}
				handlerOnChange={handlerOnChange}
				isHandlerFunction={isHandlerFunction}
				page={page}
				onAddCondition={onAddCondition}
				onDeleteCondition={onDeleteCondition}
				blockTitle={blockTitle}
				onTemplateSelectValueChange={onTemplateSelectValueChange}
				convertedTemplate={convertedTemplate}
				currentTemplate={currentTemplate}
				allTemplates={allTemplates}
				onClearTemplateClick={onClearTemplateClick}
			/>
		);
	},
);

export default CriteriasList;
