import React, { FC } from 'react';
import clsx from 'clsx';
import './DropdownWithChildren.css';

interface DropdownWithChildrenProps {
	padding?: string;
	dropdown: boolean;
	position?: 'left' | 'right'; // обычное расположение относ-но родителя
	customWidth?: boolean; // ширина 100%
	smallWidth?: boolean; // ширина маленькая
	positionMenu?: 'left' | 'right'; // расположение относ-но родителя за его пределами
}

const DropdownWithChildren: FC<DropdownWithChildrenProps> = ({
	dropdown,
	children,
	position,
	customWidth,
	smallWidth,
	padding,
	positionMenu,
}) => (
	<div
		className={clsx('', {
			customWidth,
			smallWidth,
			shown: dropdown,
			dropdown: !dropdown,
			positionLeft: position === 'left',
			positionRight: position === 'right',
			positionMenuLeft: positionMenu === 'left',
			positionMenuRight: positionMenu === 'right',
		})}
		style={{
			padding,
		}}
	>
		{children}
	</div>
);

export default DropdownWithChildren;
