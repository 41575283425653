import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

type SelectCustomPropsType = {
	width?: string;
	height?: string;
};
export const useMuiCustomSelectStyles = makeStyles<Theme, SelectCustomPropsType>((theme: Theme) => ({
	selectBox: {
		display: 'flex !important',
		alignItems: 'center',
		cursor: 'pointer',
		width: '100%',
	},
	selectMenuListInput: {
		width: '270px',
		margin: '16px 24px',
		outline: 'none',
		backgroundColor: '#F8FAFC',
		border: '1px solid #E3E8EF',
		borderRadius: '5px',
		padding: '10px 30px 10px 12px',
		color: '#738094',
		fontSize: '14px',
		position: 'relative',
	},
	selectMenuListInputIcon: {
		position: 'absolute',
		left: '262px',
		top: '23px',
		color: '#738094',
		width: '11px',
	},
	selectOption: {
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '5px 24px',
	},
	selectArrow: {
		marginRight: '10px',
	},
	selectArrowOnArrow: {
		marginBottom: '5px',
	},
	selectCheckBox: {
		'&.MuiCheckbox-root': {
			backgroundColor: '#E3E8EF !important',
		},
	},
	selectTag: {
		height: '22px !important',
		cursor: 'pointer !important',
		fontFamily: 'Inter, sans-serif !important',
		border: '2px solid #E9ECEF !important',
		borderRadius: '5px !important',
		margin: '2.5px !important',
		padding: '0 5px',
		backgroundColor: '#E9ECEF !important',
		color: '#000000 !important',
		fontSize: '12px',
		display: 'flex',
		alignItems: 'center',
	},
	selectSelectBox: {
		width: '100%',
		position: 'relative',
		minWidth: (props) => (props.width ? props.width : 'auto'),
	},
	selectPlaceholder: {
		position: 'absolute',
		left: '11px !important',
		top: '9px !important',
	},
}));

export function selectCustomStylesCreator(props: { height?: string }) {
	return {
		container: (provider: any) => ({
			...provider,
			width: '100%',
		}),
		menu: (provided: any) => ({
			...provided,
			width: '322px',
			height: '400px !important',
			overflow: 'hidden',
			overflowY: 'auto',
			zIndex: '1000',
			'&::-webkit-scrollbar': {
				width: '4px',
				backgroundColor: '#f1f1f1',
				outline: 'none',
			},
			'&::-webkit-scrollbar-thumb': {
				background: '#A3AEBE',
				height: '50px',
				borderRadius: '10px',
			},
			'&::-webkit-scrollbar-thumb:hover': {
				background: '#9298A1',
			},
		}),
		option: (provided: any, state: any) => ({
			...provided,
			cursor: 'pointer',
			padding: '10px 24px',
			fontFamily: 'Inter, sans-serif',
			fontSize: '14px',
			backgroundColor: state.isFocused ? '#F8FAFC' : '#ffffff',
			color: state.isFocused ? '#722ED1' : '#000',
			'&:hover': {
				backgroundColor: '#F8FAFC',
				color: '#722ED1',
			},
		}),
		control: (provided: any, state: any) => ({
			...provided,
			cursor: 'pointer',
			border: '1px solid #E3E8EF',
			boxShadow: 'none',
			'&:hover': {
				borderColor: '#E3E8EF',
			},
			minHeight: props.height ? props.height : '38px',
			minWidth: '70px',
			borderRadius: '5px',
			backgroundColor: '#F8FAFC',
			zIndex: '0',
			padding: 'inherit',
		}),
		dropdownIndicator: (provided: any) => ({
			...provided,
			color: '#738094',
			'&:hover': {
				color: '#738094',
			},
		}),
		placeholder: (provided: any) => ({
			...provided,
			fontSize: '14px',
			color: '#738094',
			fontFamily: 'Inter, sans-serif',
		}),
		indicatorsContainer: (provided: any) => ({
			...provided,
			'& svg path:hover': {
				fill: 'hsl(0, 0%, 80%)',
			},
			padding: '0',
		}),
		multiValue: (provided: any) => ({
			...provided,
			height: '24px !important',
			cursor: 'pointer !important',
			fontFamily: 'Inter, sans-serif !important',
			border: '2px solid #E9ECEF !important',
			borderRadius: '5px !important',
			margin: '2.5px !important',
			backgroundColor: '#E9ECEF !important',
			color: '#000000 !important',
		}),
		multiValueRemove: (provided: any) => ({
			...provided,
			backgroundColor: '#E9ECEF !important',
		}),
		valueContainer: (provided: any) => ({
			...provided,
			display: 'flex',
			padding: '0',
			paddingLeft: '5px',
		}),
	};
}

export const useStyles = (
	menuWidth = '200px',
	isSingle: boolean | undefined,
	optionColor: string,
	minHeight: string,
) => ({
	menu: (provided: any) => ({
		...provided,
		width: menuWidth || '200px',
		overflow: 'hidden',
		right: '0',
		overflowY: 'auto',
		zIndex: '1000',
		margin: '0',
		'&::-webkit-scrollbar': {
			width: '4px',
			backgroundColor: '#f1f1f1',
			outline: 'none',
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#A3AEBE',
			height: '50px',
			borderRadius: '10px',
		},
		'&::-webkit-scrollbar-thumb:hover': {
			background: '#9298A1',
		},
	}),
	control: (provided: any) => ({
		...provided,
		minHeight: minHeight || 'auto',
		cursor: 'pointer',
		border: 'none',
		boxShadow: 'none',
		backgroundColor: 'none',
		width: '100%',
		zIndex: '0',
		'&:hover': {
			border: 'none',
		},
	}),
	container: (provided: any) => ({
		...provided,
		right: '0',
	}),
	menuList: (provided: any) => ({
		...provided,
		'&::-webkit-scrollbar': {
			width: '4px',
			backgroundColor: '#f1f1f1',
			outline: 'none',
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#A3AEBE',
			height: '50px',
			borderRadius: '10px',
		},
		'&::-webkit-scrollbar-thumb:hover': {
			background: '#9298A1',
		},
	}),
	option: () => ({
		cursor: 'pointer',
		padding: '10px 24px',
		fontFamily: 'Inter, sans-serif',
		fontSize: '14px',
		backgroundColor: '#ffffff',
		color: optionColor || '#000',
		'&:hover': {
			backgroundColor: '#F8FAFC',
			color: '#722ED1',
		},
	}),
	valueContainer: () => ({
		padding: '0',
	}),
	singleValue: () => ({
		display: isSingle ? 'none' : 'block',
	}),
	input: () => ({
		margin: '0',
		width: '1px',
		height: '1px',
	}),
});
