import React, { FC, PropsWithChildren, memo } from 'react';
import Select, { components } from 'react-select';
import { PlusIcon } from 'shared/ui';
import { MinusSvg } from '../TextSelectOLD/TextSelect.svg';
import { useStyles } from './LabelSelect.jss';

export type OptionType = {
	label: string;
	value: any;
};

type MultiValueSelect = {
	// значения
	// Значение может быть либо массивом, либо одиночным значением, либо null
	value: OptionType[] | OptionType | null;
	// все предлогаемые значения
	options: OptionType[];

	// при изменении значений селекта
	valueHandler: any;

	// иконка удаления (по дефолту крестик с бэкграундом)
	customLabel?: React.ReactElement;
	notPlusMinus?: boolean;

	closeMenuSelect?: boolean;
	isDisable?: boolean;

	menuWidth?: string;
	optionColor?: string;

	isSingle?: boolean;

	className?: string;

	menuIsOpen?: boolean;

	altSearchSelected?: boolean;

	testId?: string;

	minHeight?: string;

	customControlStyles?: React.CSSProperties;
};

function isSelect(value: string, selectValues: OptionType[], altSearchSelected: boolean): boolean {
	if (value === 'alternativeSearch') {
		return altSearchSelected;
	}
	return !!selectValues.find((selectValue) => selectValue.label === value);
}

const CustomControl: FC<PropsWithChildren<any>> = memo(({ customLabel, customControlStyles, ...props }) => (
	<components.Control {...props}>
		{customLabel}
		<div style={{ position: 'absolute', ...customControlStyles }}>{props.children}</div>
	</components.Control>
));

const CustomOption: FC<any> = memo(({ children, selectValues, notPlusMinus, altSearchSelected, ...props }) => (
	<components.Option {...props}>
		<div
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				cursor: 'pointer',
			}}
		>
			{children}
			{Boolean(!notPlusMinus) &&
				(isSelect(children, selectValues, altSearchSelected) ? <MinusSvg /> : <PlusIcon />)}
		</div>
	</components.Option>
));

const LabelSelect: FC<MultiValueSelect> = ({
	value,
	options,
	valueHandler,
	customLabel,
	notPlusMinus,
	closeMenuSelect,
	isDisable,
	menuWidth,
	isSingle,
	className,
	optionColor = '',
	menuIsOpen,
	altSearchSelected,
	testId,
	minHeight = '',
	customControlStyles,
}) => {
	const styles = useStyles(menuWidth, isSingle, optionColor, minHeight);

	return (
		<Select
			data-testid={testId}
			menuIsOpen={menuIsOpen}
			className={className}
			menuPlacement={isSingle ? 'bottom' : 'auto'}
			isDisabled={isDisable}
			styles={styles}
			isSearchable={false}
			closeMenuOnSelect={closeMenuSelect}
			isMulti={!isSingle}
			tabIndex={0}
			options={options}
			hideSelectedOptions={false}
			onChange={valueHandler}
			components={{
				/* eslint-disable react/no-unstable-nested-components */
				Control: (props) => (
					<CustomControl {...props} customLabel={customLabel} customControlStyles={customControlStyles} />
				),
				Option: (props) => (
					<CustomOption
						{...props}
						selectValues={value}
						altSearchSelected={altSearchSelected}
						notPlusMinus={notPlusMinus}
					/>
				),
				IndicatorsContainer: () => null,
				Placeholder: () => null,
				MultiValue: () => null,
			}}
		/>
	);
};

export default LabelSelect;
