import React, { FC, ReactNode } from 'react';
import { IconQuestion } from 'components/Icons/IconQuestion';
import Tooltip from 'components/common/Tooltip/Tooltip';
import { ContainedSelect } from '../index';
import { ContainedSelectPropsType } from './ContainedSelect';

interface ContainedSelectWithLabelProps extends ContainedSelectPropsType {
	title: string;
	classNameRoot?: string;
	classNameLabel?: string;
	classNameWrapper?: string;
	required?: boolean;
	hasError?: boolean;
	labelBrother?: ReactNode;
	tooltipTitle?: string;
}
const ContainedSelectWithLabel: FC<ContainedSelectWithLabelProps> = ({
	classNameRoot,
	classNameLabel,
	classNameWrapper,
	title,
	required = false,
	hasError = false,
	labelBrother,
	testId,
	tooltipTitle,
	...props
}) => (
	<div className={classNameRoot}>
		<div className={classNameWrapper}>
			<p className={classNameLabel}>
				{title}
				{required && <span style={{ color: 'red' }}>*</span>}
			</p>
			{tooltipTitle && (
				<div style={{ marginLeft: '4px' }}>
					<Tooltip title={tooltipTitle} placement="right" background="dark">
						<IconQuestion />
					</Tooltip>
				</div>
			)}
			{labelBrother}
		</div>
		<ContainedSelect
			testId={testId}
			controlStyles={
				hasError
					? {
							borderColor: 'red !important',
					  }
					: {}
			}
			{...props}
		/>
	</div>
);

export default ContainedSelectWithLabel;
