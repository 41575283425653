/* eslint-disable */
import { FC } from 'react';
import { makeStyles } from '@mui/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

export type TooltipPropsType = {
	children: any;
	title: string | JSX.Element;
	placement?:
		| 'bottom-end'
		| 'bottom-start'
		| 'bottom'
		| 'left-end'
		| 'left-start'
		| 'left'
		| 'right-end'
		| 'right-start'
		| 'right'
		| 'top-end'
		| 'top-start'
		| 'top';
	followCursor?: boolean;
	background?: 'dark' | 'white';
};

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))({
	[`& .${tooltipClasses.tooltip}`]: {
		maxWidth: 200,
		fontFamily: 'Inter, sans-serif !important',
		color: '#fff !important',
		backgroundColor: '#2F3747 !important',
		boxShadow: '0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 3px 6px -4px rgba(0, 0, 0, 0.12)',
		filter: 'drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.08))',
	},
	[`& .${tooltipClasses.arrow}`]: {
		'&:before': {
			border: '1px solid #2F3747',
		},
		color: '#2F3747 !important',
	},
});

const TooltipCustom: FC<TooltipPropsType> = ({ children, title, placement, followCursor, background }) => {
	const useStyles = makeStyles({
		tooltip: {
			fontFamily: 'Inter, sans-serif !important',
			color: '#000 !important',
			backgroundColor: '#E3E8EF !important',
		},
	});
	const classes = useStyles();
	return (
		<>
			{background === 'dark' ? (
				<CustomWidthTooltip
					arrow
					followCursor={Boolean(followCursor)}
					disableInteractive
					title={title}
					placement={placement || 'top'}
				>
					<div>{children}</div>
				</CustomWidthTooltip>
			) : (
				<Tooltip
					followCursor={Boolean(followCursor)}
					disableInteractive
					classes={{ tooltip: classes.tooltip }}
					title={title}
					placement={placement || 'top'}
				>
					<div>{children}</div>
				</Tooltip>
			)}
		</>
	);
};

export default TooltipCustom;
